import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import InsuranceDescription from "../components/insuranceDescription"
import QuoteForm from "../components/quoteForm"
import StockImage from "../components/stockImage"
import CallIcon from "../components/callIcon"

import InsuranceImage from "../images/stock-image-7.jpg"
import InsuranceImageMobile from "../images/stock-image-7-mobile.jpg"
import InsuranceImageTablet from "../images/stock-image-7-tablet.jpg"
import DentalImage from "../images/stock-image-8.jpg"
import DentalImageMobile from "../images/stock-image-8-mobile.jpg"
import DentalImageTablet from "../images/stock-image-8-tablet.jpg"
import IconId from "../images/icon-id.svg"
import IconHealthcare from "../images/icon-healthcare.svg"
import IconPiggy from "../images/icon-piggy-bank.svg"
import IconDesktop from "../images/icon-desktop.svg"
import IconPeople from "../images/icon-people.svg"
import IconCheck from "../images/icon-check.svg"
import DentalEquipmentSrc from "../images/stock-image-9.jpg"
import DentalManipulationSrc from "../images/stock-image-10.jpg"

const AdvantageListWrapper = styled.div`
  @media (max-width: 767px) {
    width: 1400px;
  }
`

const Advantage = styled.div`
  @media (max-width: 767px) {
    max-width: 280px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

const IconWrapper = styled.div`
  width: 82px;
  height: 82px;
  flex: 1 0 82px;
`

const Icon = styled.svg`
  width: 100%;
  height: 100%;
`

const DentalManipulationImage = styled.img`
  width: 100%;
  border-radius: 0 0 15px 15px;
`

const FuncItem = styled.div`
  @media (min-width: 768px) {
    min-height: 125px;
  }
`

const DentalInsurance = () => {
  const { t } = useTranslation()

  const advantages = [
    {
      title: t("di_adv0_title"),
      desc: t("di_adv0_desc"),
      icon: IconId,
    },
    {
      title: t("di_adv1_title"),
      desc: t("di_adv1_desc"),
      icon: IconHealthcare,
    },
    {
      title: t("di_adv2_title"),
      desc: t("di_adv2_desc"),
      icon: IconPiggy,
    },
    {
      title: t("di_adv3_title"),
      desc: t("di_adv3_desc"),
      icon: IconDesktop,
    },
    {
      title: t("di_adv4_title"),
      desc: t("di_adv4_desc"),
      icon: IconPeople,
    },
  ]

  const function_steps = [
    {
      title: t("di_func0_title"),
      desc: t("di_func0_desc"),
      icon: IconCheck,
    },
    {
      title: t("di_func1_title"),
      desc: t("di_func1_desc"),
      icon: IconCheck,
    },
    {
      title: t("di_func2_title"),
      desc: t("di_func2_desc"),
      icon: IconCheck,
    },
  ]

  return (
    <Layout>
      <SEO title={t("dental_insurance")} />

      <InsuranceDescription
        title={t("dental_insurance_title")}
        subtitle={t("dental_insurance_subtitle")}
        content={t("dental_insurance_description")}
        actions={
          <>
            <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary btn-sm">
              <CallIcon /> {APREMIA_PHONE_FORMATTED}
            </a>
            <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
              {t("we_call_you")}
            </a>
          </>
        }
        image={InsuranceImage}
        imageMobile={InsuranceImageMobile}
        imageTablet={InsuranceImageTablet}
      />

      <div className="bg-white py-5 py-md-6">
        <Container className="gx-0 gx-md-3">
          <h1 className="equalize-padding-x">
            {t("dental_insurance_advantages")}
          </h1>

          <div className="hide-scroll-x">
            <AdvantageListWrapper className="d-flex flex-md-column px-lg-9 pt-4 equalize-padding-x">
              {advantages.map((item, index) => (
                <Advantage
                  key={index}
                  className="d-flex flex-column flex-md-row align-items-start"
                >
                  <IconWrapper className="me-6">
                    <Icon as={item.icon} />
                  </IconWrapper>
                  <div>
                    <h3 className="mb-1">{item.title}</h3>
                    <p className="fw-light">{item.desc}</p>
                  </div>
                </Advantage>
              ))}
            </AdvantageListWrapper>
          </div>
        </Container>
      </div>

      <div className="bg-secondary text-white">
        <InsuranceDescription
          title={t("dental_insurance_title_2")}
          subtitle={t("dental_insurance_subtitle_2")}
          content={t("dental_insurance_description_2")}
          image={DentalImage}
          imageMobile={DentalImageMobile}
          imageTablet={DentalImageTablet}
          inverted
          dark
        />
      </div>

      <div className="bg-white py-md-6">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 6, order: 0 }}
              className="d-flex flex-column justify-content-center equalize-padding-x pe-md-4 pe-lg-6 pb-4 pb-md-0"
            >
              <h3>{t("dental_insurance_title_3")}</h3>
              <span className="fw-light">
                {t("dental_insurance_subtitle_3")}
              </span>
            </Col>
            <Col
              xs={{ span: 12, order: 0 }}
              lg={{ span: 6, order: 1 }}
              className="text-end"
            >
              <StockImage src={DentalEquipmentSrc} />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mb-4">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col>
              <DentalManipulationImage src={DentalManipulationSrc} />
            </Col>
          </Row>
          <Row className="gx-0 gx-md-3 mt-4 mt-md-5">
            <Col xs="12" lg className="equalize-padding-x">
              <h1>{t("dental_insurance_title_4")}</h1>
              <h3>{t("dental_insurance_subtitle_4")}</h3>
            </Col>
            <Col xs="12" lg className="equalize-padding-x px-lg-4">
              <span className="fw-light">
                {t("dental_insurance_description_4")}
              </span>
            </Col>
          </Row>

          <hr className="my-5 my-md-6" />

          <h1 className="equalize-padding-x">
            {t("dental_insurance_function")}
          </h1>
          <div className="d-flex flex-column px-lg-9 pt-4 equalize-padding-x">
            {function_steps.map((item, index) => (
              <FuncItem
                key={index}
                className="d-flex flex-column flex-md-row align-items-start pe-xl-8"
              >
                <IconWrapper className="d-none d-md-block me-6">
                  <Icon as={item.icon} />
                </IconWrapper>
                <div className="pe-md-8">
                  <h3 className="mb-1">{item.title}</h3>
                  <p className="fw-light">{item.desc}</p>
                </div>
              </FuncItem>
            ))}
          </div>
        </Container>
      </div>

      <QuoteForm bordered />
    </Layout>
  )
}

export default DentalInsurance

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
