import React from "react"
// import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import classNames from "classnames"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Container, Row, Col } from "react-bootstrap"

const Image = styled.img`
  width: 100%;
`

const ImageWrapper = styled.div`
  position: relative;
  // @media(min-width: 768px) and (max-width: 1024px) {
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100px;
  //     background: rgb(255,255,255);
  //     background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, ${props => props.dark ? 'rgba(134, 149, 204, 1)' : 'rgba(245,247,255,1)'} 90%);
  //     background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, ${props => props.dark ? 'rgba(134, 149, 204, 1)' : 'rgba(245,247,255,1)'} 90%);
  //     background: linear-gradient(180deg, rgba(255,255,255,0) 0%, ${props => props.dark ? 'rgba(134, 149, 204, 1)' : 'rgba(245,247,255,1)'} 90%);
  //   }
  // }
`

const InsuranceDescription = props => {
  const {
    title, 
    subtitle, 
    content,
    actions,
    image, 
    imageMobile,
    imageTablet,
    inverted,
    dark
  } = props

  // const { t } = useTranslation()
  const breakpoints = useBreakpoint()

  const getImage = () => {
    if (breakpoints.sm && !!imageMobile) {
      return imageMobile
    } else if (breakpoints.md && !!imageTablet) {
      return imageTablet
    } else {
      return image
    }
  }

  return (
    <Container fluid="lg" className="gx-0 gx-lg-3">
      <Row className="gx-0">
        <Col
          xs={{ span: 12, order: 1 }}
          lg={{ span: 6, order: inverted ? 1 : 0 }}
          className={classNames(
            "render-tags d-flex flex-column align-items-start justify-content-center py-4",
            { "pe-lg-6": !inverted }
          )}
        >
          <Container className="gx-3 gx-lg-0">
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
            {!!content && (<p className="fw-light">{content}</p>)}
            {!!actions && (
              <div className="mb-4 mb-lg-0">
                {actions}
              </div>
            )}
          </Container>
        </Col>

        <Col
          xs={{ span: 12, order: 0 }}
          lg={{ span: 6, order: inverted ? 0 : 1 }}
          className={classNames({ "pe-lg-6": inverted })}
        >
          <ImageWrapper dark={dark}>
            <Image src={getImage()} />
          </ImageWrapper>
        </Col>
      </Row>
    </Container>
  )
}

export default InsuranceDescription
