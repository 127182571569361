import styled from "styled-components"

const StockImage = styled.img`
  width: 536px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`

export default StockImage