import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"
import { submitMessageRequest } from "../services"

import CallIcon from "./callIcon"

import SuccessSrc from "../images/success.png"

const QuoteFormWrapper = styled.div`
  background-color: ${props => props.theme.colors["body-color"]};
  background-image: url("/images/quote-bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 385px;
  @media (min-width: 1700px) {
    background-size: 1366px auto;
  }
`

const QuoteFormSentMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;

  & > img {
    height: 120px;
    margin-bottom: 1rem;
  }
`

const initialValues = {
  name: "",
  phoneNumber: "",
  email: "",
  callTime: "Mañana 8:00 am - 12:00 pm",
  message: "",
}

// RegEx for phone number validation
const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const QuoteForm = (props) => {
  const { bordered = false } = props
  const { t } = useTranslation()

  const [ visibleQuoteFormSentMessage, setVisibleQuoteFormSentMessage ] = useState(false)
  const [ sending, setSending ] = useState(false)
  const [ formError, setFormError ] = useState(null)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("required_field_error")),
    email: Yup.string().email(t("format_invalid_field_error")).required(t("required_field_error")),
    phoneNumber: Yup.string().matches(
      phoneRegExp,
      t("format_invalid_field_error")
    ).required(t("required_field_error")),
    message: Yup.string(),
  })

  return (
    <QuoteFormWrapper id="quote-form" className="pt-9 pt-md-15 pt-lg-5">
      <Container className="pb-1">
        <Row>
          <Col
            xs="12"
            lg="7"
            className="d-flex flex-column align-items-start justify-content-end gx-7 gx-md-5 pe-lg-7 mb-4 mb-md-5"
          >
            <h1 className="text-white">{t("quote_heading")}</h1>
            <h3 className="text-white">{t("quote_subtitle")}</h3>
            <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary">
              <CallIcon /> {APREMIA_PHONE_FORMATTED}
            </a>
          </Col>

          <Col xs="12" lg="5">
            <div className="bg-white rounded-3 p-3 p-md-4 position-relative">
              {visibleQuoteFormSentMessage && (
                <QuoteFormSentMessage onClick={() => setVisibleQuoteFormSentMessage(false)}>
                  <img src={SuccessSrc} alt="success" />
                  <h2>{t("form_sent_title")}</h2>
                  <h4>{t("form_sent_subtitle")}</h4>
                </QuoteFormSentMessage>
              )}

              <p className="fw-bold">{t("quote_form_description")}</p>

              <p className="fw-bold text-danger">
                {formError}
              </p>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  try {
                    setSending(true)
                    await submitMessageRequest(values);
                    setVisibleQuoteFormSentMessage(true);
                  } catch (err) {
                    setFormError('Tuvimos un problema guardando su información, por favor intente nuevamente')
                  }
                  finally {
                    setSending(false)
                  }
                }}
              >
                {props => {
                  const {
                    values,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                  } = props

                  return (
                    <Form onSubmit={handleSubmit}>
                      <fieldset disabled={isSubmitting}>
                        <Form.Group className="mb-3" controlId="formName">
                          <Form.Label className="required">{t("form_name")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPhoneNumber">
                          <Form.Label className="required">{t("form_phone_number")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.phoneNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phoneNumber}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                          <Form.Label className="required">{t("form_email")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formCallTime">
                          <Form.Label>¿A qué hora deseas que te llamemos?</Form.Label>
                          <Form.Select
                            name="callTime"
                            value={values.callTime}
                            onChange={handleChange}
                          >
                            <option value="Mañana 8:00 am - 12:00 pm">Mañana 8:00 am - 12:00 pm</option>
                            <option value="Tarde 12:00 pm - 6:00 pm">Tarde 12:00 pm - 6:00 pm</option>
                            <option value="Noche 6:00 pm - 8:00 pm">Noche 6:00 pm - 8:00 pm</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formMessage">
                          <Form.Label>{t("form_message")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="message"
                            value={values.message}
                            onChange={handleChange}
                            isInvalid={!!errors.message}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </fieldset>

                      <Button type="submit" disabled={isSubmitting}>
                        {sending ? 'Enviando...' : t("form_submit")}
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </Col>
        </Row>

        {/* ----- Separator ----- */}
        {bordered ? (<hr className="opacity-25 mt-5 mb-0 mx-n20" />) : (<div className="pt-5"></div>)}
      </Container>
    </QuoteFormWrapper>
  )
}

export default QuoteForm
